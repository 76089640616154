import { Component, Prop, Watch } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import { Conversation, ConversationNewEntryQueue } from "@/app/store/modules/conversation/conversation.models";
import { store, storeTypes } from "@/app/store";
import * as moment from "moment";
import Axios from "axios";
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from "@/config";
@Component({ name: 'search-conversation-widget' })
export default class SearchConversationWidget extends VueWizard {

    @Prop() public readonly openConversations!: any[];
    @Prop() public readonly closeConversations!: any[];
    @Prop() public readonly queueConversations!: any[];
    @Prop() public readonly agentTopics!: any;

    public searchKeyWord = '';
    public activeSearchCategory = 1;
    public topicSearchFilterValue: any = null;
    public searchResults: any[] = [];

    public openResultsQuantity = 0;
    public closeResultsQuantity = 0;
    public queueResultsQuantity = 0;

    getUserPicture(item: any): any {
        let profileurl = null;

        let conversation: Conversation;
        if (item.variables) {
            conversation = item;
        } else {
            conversation = item.conversation;
        }

        if (conversation != undefined && conversation.variables != undefined && conversation.variables.CONSUMER_PROFILE_PICTURE) {
            profileurl = conversation.variables.CONSUMER_PROFILE_PICTURE.value;
        }

        return profileurl;
    }

    getConsumer(item: Conversation): string {
        let consumer = item.currentConsumer
            ? item.currentConsumer
            : item.consumer
                ? item.consumer
                : "";
        if (consumer.includes("@")) consumer = consumer.split("@")[0];
        return consumer;
    }

    formatUpdatedDate(date: Date | string): string {
        moment.locale('es');
        return moment.default(date).calendar();
    }

    getUserName(item: any): any {
        let userName = "";
        let conversation: Conversation;
        if (item.variables) {
            conversation = item;
        } else {
            conversation = item.conversation;
        }
        if (conversation != undefined && conversation.variables != undefined && conversation.variables.CONSUMER_NAME) {
            userName = conversation.variables.CONSUMER_NAME.value;
        }
        return userName;
    }

    get currentConversation(): any {
        return store.state.conversation!.currentConversation;
    }

    cancelSearch(): void {
        this.searchKeyWord = '';
    }

    selectResultConversation(conversation: any): void {
        this.cancelSearch();
        this.$emit('selectConversation', conversation)
    }

    @Watch('searchKeyWord')
    async onSearchChange(): Promise<void> {
        this.searchResults = [];

        let openConversations: any = await this.getfilteredOpenConversations();
        this.openResultsQuantity = openConversations!.length;

        let closeConversations: any = await this.getFilteredCloseConversations();
        this.closeResultsQuantity = closeConversations!.length;

        let queueConversations: any = await this.getFilteredQueueConversations();
        this.queueResultsQuantity = queueConversations!.length;

        this.obtainSearchResults();
    }

    async getfilteredOpenConversations(): Promise<any> {
        let url = `${BASE_URL_ORCHESTATOR}/agent/${store.state.agent!.agent!._id}/conversation/open`;
        let res = await Axios.get(url, {
            params: {
                limit: 10,
                page: 0,
                keyword: this.searchKeyWord,
            }
        });
        return res.data.conversations.map((conversation: any) => Object.assign(conversation, { state: 'open' }));
    }


    async getFilteredCloseConversations(): Promise<any> {
        let url = `${BASE_URL_ORCHESTATOR}/agent/${store.state.agent!.agent!._id}/conversation/close`;
        let res = await Axios.get(url, {
            params: {
                limit: 10,
                page: 0,
                keyword: this.searchKeyWord,

            }
        });
        return res.data.conversations.map((conversation: any) => Object.assign(conversation, { state: 'close' }));

    }

    async getFilteredQueueConversations(): Promise<any> {

        let url = `${BASE_URL_MANAGER}/queue`;
        let res = await Axios.get(url, {
            params: {
                subscriptions: store.state.agent!.agent!.subscriptions,
                limit: 10,
                page: 0,
                keyword: this.searchKeyWord,
            }
        });
        return res.data.queues.map((conversation: any) => Object.assign(conversation, { state: 'queue' }));

    }

    @Watch('activeSearchCategory')
    @Watch('topicSearchFilterValue')
    async obtainSearchResults(): Promise<any> {

        if (this.activeSearchCategory === 1) {
            this.searchResults = await this.getfilteredOpenConversations();
        } else if (this.activeSearchCategory === 2) {
            this.searchResults = await this.getFilteredCloseConversations();
        } else if (this.activeSearchCategory === 3) {
            this.searchResults = await this.getFilteredQueueConversations();
        }

        if (this.topicSearchFilterValue) {
            this.searchResults = this.searchResults.filter(
                (conversation: any) => this.getTopic(conversation) === this.getFilterTopic(this.topicSearchFilterValue)
            );
        }

    }

    getTopic(item: ConversationNewEntryQueue): string {
        return this.cleanTopic(item.topic ? item.topic : (item.currentTopic ? item.currentTopic : ""));
    }

    getFilterTopic(item: string): string {
        return this.cleanTopic(item);
    }

    cleanTopic(topic: string): string {
        let topicElements: any[] = [];
        if (topic.includes("_")) {
            topicElements = topic.split("_");
            topic = "";
            for (let i = 2; i < topicElements.length; i++) {
                topic = topic + topicElements[i] + " ";
            }
            topic.trim();
        }
        return topic;
    }

}