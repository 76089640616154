import { VueWizard } from "@/vue-wizard";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as bootstrap from "bootstrap"
import $ from 'jquery';

@Component({
    name: 'image-holder-modal'
})
export default class ImageHolderModal extends VueWizard{
    @Prop() readonly formatedFileName !: string;
    @Prop() readonly dataFileUrl !: string;

    public rotate = 0;

    changeRotate() {
        if (this.rotate < 3) {
            this.rotate++;
        } else {
            this.rotate = 0;
        }
    }

    changeRotateMin() {
        if (this.rotate === 0) {
            this.rotate = 3;
        } else {
            this.rotate--;
        }
    }

    enableModal(){
        $(`#${this.formatedFileName}`).modal('show');
    }

    disableModal(){
        $(`#${this.formatedFileName}`).modal('hide');
    }

    
}