import { VueWizard } from "@/vue-wizard";
import Component from 'vue-class-component';
import {BASE_URL_MANAGER} from "@/config";
import { Prop } from 'vue-property-decorator';
import axios from 'axios';

@Component({
    name: 'file-holder'
})
export default class FileHolder extends VueWizard{
    @Prop() readonly fileUrl !: any;
    @Prop() fileName !: any;

    public dataFileUrl : string = '';
    public localFileName : string = ''

    mounted(){
        this.localFileName= this.fileName.split('/')[1].split('_')[1];
    }

    download() {
        axios.post(`${BASE_URL_MANAGER}/company/getfile`, {
            urlfile: this.fileUrl
        }).then(async (response) => {
            this.dataFileUrl = response.data.file;
            const link = document.createElement("a");
            link.download = this.fileUrl;
            link.href = response.data.file;
            link.target = "_blank";
            await link.click();
            this.dataFileUrl = "";
            document.removeChild(link);
        });
    }

    getSubString(newString: string): string{
        if(newString.length > 80){
            let extention = newString.substring(newString.length-4, newString.length);
            return newString.substring(0,81) + '...' + extention
        }
        return newString
    }

    
}