import { render, staticRenderFns } from "./ConversationsCategoryTabs.vue?vue&type=template&id=4cb6035a&"
import script from "./conversations-category-tabs.ts?vue&type=script&lang=ts&"
export * from "./conversations-category-tabs.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports