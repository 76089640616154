import { render, staticRenderFns } from "./FileHolder.vue?vue&type=template&id=3ff1bc87&scoped=true&"
import script from "./file-holder.ts?vue&type=script&lang=ts&"
export * from "./file-holder.ts?vue&type=script&lang=ts&"
import style0 from "./file-holder.scss?vue&type=style&index=0&id=3ff1bc87&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ff1bc87",
  null
  
)

export default component.exports