import { filter } from "rxjs/operators";
import { conversation } from "./../../store/modules/conversation/index";
import { agent } from "./../../store/modules/agent/index";
import {
  BASE_URL_ORCHESTATOR,
  BASE_URL_WHATSAPP_CLOUD,
} from "./../../../config";
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Vue, Watch } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import Axios, { AxiosResponse } from "axios";
import { BASE_URL_MANAGER } from "@/config";
import { store, storeTypes } from "@/app/store";
import Swal from "sweetalert2";
import $ from "jquery";

import { conversationActionsTypes } from "@/app/store/modules/conversation/conversation.actions";
import { conversationMutationsTypes } from "@/app/store/modules/conversation/conversation.mutations";
import { SOCKET_EVENTS } from "@/app/store/modules/agent/agent.models";
import WebSocketService from "@/app/services/web-socket";
// Models
import {
  BusinessStatus,
  Conversation,
  ConversationNewEntryQueue,
  Interaction,
  InteractionOrigin,
  MessageType,
} from "@/app/store/modules/conversation/conversation.models";
// Components
import ImageHolderModal from "@/app/components/shared/image-holder-modal/ImageHolderModal.vue";
import AgentSidebar from "@/app/views/agent-sidebar/agent-sidebar.vue";
import ConversationFormSend from "@/app/components/conversation/conversationFormSend/ConversationFormSend.vue";
import ConversationContent from "@/app/components/conversation/conversationContent/ConversationContent.vue";
import NewQuickResponseModal from "@/app/components/agents-management/dedicated-components/new-quick-response-modal/NewQuickResponseModal.vue";
import BusinessStatusSelector from "@/app/components/chat/business-status-selector/BusinessStatusSelector.vue";
import ChangeConversationStatusModal from "@/app/components/chat/change-conversation-status-modal/ChangeConversationStatusModal.vue";
import ResolveConversationModal from "@/app/components/chat/resolve-conversation-modal/ResolveConversationModal.vue";
import ConversationsCategoryList from "@/app/components/chat/conversations-category-list/ConversationsCategoryList.vue";
import ConversationsCategoryTabs from "@/app/components/chat/conversations-category-tabs/ConversationsCategoryTabs.vue";
import SearchConversationWidget from "@/app/components/chat/search-conversation-widget/SearchConversationWidget.vue";
import TransferConversationModal from "@/app/components/chat/transfer-conversation-modal/TransferConversationModal.vue";
import ContactDataManager from "@/app/components/chat/contact-data-manager/ContactDataManager.vue";
import DropiInfoManager from "@/app/components/chat/dropi-info-manager/DropiInfoManager.vue";
//import { Navigation, Pagination } from 'swiper'
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
//import 'swiper/swiper-bundle.css'

const _ = require("lodash");
import { Swiper, SwiperSlide } from "swiper-vue2";
import eventBus from "@/main";
require("/src/assets/css/app.css");
//SwiperCore.use([Navigation, Pagination])
@Component({
  name: "agent-chat",
  components: {
    PerfectScrollbar,
    ConversationContent,
    ConversationFormSend,
    NewQuickResponseModal,
    ImageHolderModal,
    BusinessStatusSelector,
    ResolveConversationModal,
    ChangeConversationStatusModal,
    ConversationsCategoryList,
    ConversationsCategoryTabs,
    SearchConversationWidget,
    ContactDataManager,
    DropiInfoManager,
    TransferConversationModal,
    AgentSidebar,
    Swiper,
    SwiperSlide,
  },
})
export default class AgentChat extends VueWizard {
  public activeTab: string = "encola";
  public whatsappicon = "";
  public spinConversation = false;
  public currentList = 2;
  public unsubscribe: any;
  public visible = false;
  public EventBus: Vue = new Vue();
  public agentQuickResponses: any = [];
  public topicQuickResponses: any = [];
  public currentAgentId = "";
  public filterConversation = false;
  public formatedFileName: any = "";
  public dataFileUrl: any = "";
  public objectConversationUpdate: any = null;
  public maxHeightSubstract = 0;

  public topicFilterValue: any = null;
  public alertIsOpen: any = false;

  public webSocketService: WebSocketService;
  public showRefreshText = false;
  public showChangeStatusText = false;
  public showResolveText = false;

  public businessStatuses: BusinessStatus[] = [];
  public showStatusSelector = false;
  public showSelectStatusModal = false;
  public selectedStatus: BusinessStatus | null = null;
  public showResolveConversationModal = false;

  public activeBusinessStatusFilterValue: string | null = null;
  public inacttivedBusinessStatusFilterValue: string | null = null;
  public resolvedBusinessStatusFilterValue: string | null = null;

  public resolvedActiveFilters: string[] = [];
  public inactivedActiveFilters: string[] = [];
  public activeActiveFilters: string[] = [];

  public showTransferConversationModal = false;
  public showTransferText = false;
  public queueActiveFilters: string[] = [];

  public currentTab: string = "encola";

  public project: any;
  public isError: boolean = false;
  public errors: any = [];

  public templates: any = [];
  public startConverstionObject: any = {
    phone: "",
    name: "",
    text: "",
  };
  constructor() {
    super();
    this.webSocketService = WebSocketService.instance();
  }
  notifyOpenModal() {
    eventBus.$emit("openModal");
  }
  onScroll(event: any) {
    console.log(event);
  }
  onSwiper(swiper: any) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log("slide change");
  }
  statusColorStyle(status: BusinessStatus): any {
    if (status) {
      return {
        "--label-bg-color-r": this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).r
          : 0,
        "--label-bg-color-g": this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).g
          : 0,
        "--label-bg-color-b": this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).b
          : 0,
      };
    } else {
      return {};
    }
  }

  async onResolveNormally(): Promise<void> {
    this.showResolveConversationModal = false;
    // store.commit(conversationMutationsTypes.setLoading(true));
    await this.closeConversation();
    // store.commit(conversationMutationsTypes.setLoading(false));
    Vue.$toast.success("La conversacion ha sido cerrada", {
      timeout: 2000,
    });
  }

  async onResolveByBusinessStatus(event: BusinessStatus): Promise<void> {
    this.showResolveConversationModal = false;
    //store.commit(conversationMutationsTypes.setLoading(true));
    await this.changeConversationStatus(event);
    await this.closeConversation();
    //  store.commit(conversationMutationsTypes.setLoading(false));
    Vue.$toast.success("La conversacion ha sido cerrada", {
      timeout: 2000,
    });
  }

  onSelectedStatus(event: BusinessStatus): void {
    this.selectedStatus = event;
    this.showStatusSelector = false;
    this.showSelectStatusModal = true;
  }

  onModalNoConfirm(): void {
    this.selectedStatus = null;
    this.showSelectStatusModal = false;
    this.showResolveConversationModal = false;
    this.showTransferConversationModal = false;
  }

  async changeConversationStatus(event: BusinessStatus): Promise<any> {
    this.selectedStatus = null;
    this.showSelectStatusModal = false;
    store.commit(conversationMutationsTypes.setLoading(true));
    return new Promise((resolve, reject) => {
      store
        .dispatch(conversationActionsTypes.changeConversationStatus(event))
        .then(async () => {
          await this.loadAgent();
          this.$notification.success({
            placement: "bottomRight",
            message: "Cambio realizado",
            description: `El estado de la conversación ha cambiado`,
          });
          resolve(true);
        })
        .catch(() => {
          this.$notification.error({
            message: "Ha ocurrido un problema",
            description: `No se pudo cambiar el estado de la conversación`,
          });
          reject();
        })
        .finally(() =>
          store.commit(conversationMutationsTypes.setLoading(false))
        );
    });
  }

  get noFinishBusinessStatuses(): BusinessStatus[] {
    return this.businessStatuses.filter(
      (businessStatus: BusinessStatus) => !businessStatus.finish
    );
  }

  get finishBusinessStatuses(): BusinessStatus[] {
    return this.businessStatuses.filter(
      (businessStatus: BusinessStatus) => businessStatus.finish
    );
  }

  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  getBusinessStatus(status: string): BusinessStatus | undefined {
    return this.businessStatuses.find(
      (businessStatus: BusinessStatus) => businessStatus.name === status
    );
  }

  get filteredAgentQueue(): any {
    this.queueActiveFilters = [];
    if (this.topicFilterValue) {
      this.queueActiveFilters.push(this.getFilterTopic(this.topicFilterValue));
      return this.agentQueue.filter(
        (conversation: ConversationNewEntryQueue) =>
          this.getTopic(conversation) ===
          this.getFilterTopic(this.topicFilterValue)
      );
    } else {
      return this.agentQueue;
    }
  }

  get filteredAgentQueueConversations(): Conversation[] {
    const conversations: Conversation[] = [];
    this.filteredAgentQueue.forEach((queue: ConversationNewEntryQueue) =>
      conversations.push(queue.conversation)
    );
    return conversations;
  }

  get noFilteredAgentQueueConversations(): Conversation[] {
    const conversations: Conversation[] = [];
    this.agentQueue.forEach((queue: ConversationNewEntryQueue) =>
      conversations.push(queue.conversation)
    );
    return conversations;
  }

  get actualPage(): number {
    return store.state.agent!.actualPage;
  }

  get agentTopics(): string[] {
    return store.state.agent!.agent.subscriptions;
  }

  get arrayActiveConversationFilter(): Conversation[] {
    this.activeActiveFilters = [];
    let conversations: Conversation[] = this.agentConversations;
    if (this.filterConversation) {
      this.activeActiveFilters.push("No respondidas");
      conversations = this.agentConversations.filter(
        (filter: any) => filter.newinteractions > 0
      );
    }
    if (this.activeBusinessStatusFilterValue) {
      this.activeActiveFilters.push(this.activeBusinessStatusFilterValue);
      conversations = this.agentConversations.filter((filter: any) => {
        if (
          this.activeBusinessStatusFilterValue === "TRANSFERRED" ||
          this.activeBusinessStatusFilterValue === "ACTIVE"
        ) {
          return filter.status === this.activeBusinessStatusFilterValue;
        } else {
          return filter.bussinesStatus === this.activeBusinessStatusFilterValue;
        }
      });
    }
    return conversations;
  }

  // listen emit events
  private listen(): void {
    eventBus.$on("Error-Interaction", (payload: any) => {
      this.isError = payload.isError;
      this.errors = payload.errors;
    });
  }

  async mounted(): Promise<any> {
    await this.getProject();
    this.listen();

    // Variable Declarations

    var right_sidebar = $(".right-sidebar").width();
    var left_sidebar = $(".left-sidebar").width();
    var chat_bar = $(".chat").width();
    var win_width = $(window).width();

    $(
      ".user-list-item:not(body.status-page .user-list-item, body.voice-call-page .user-list-item)"
    ).on("click", function () {
      if (win_width != undefined && win_width < 992) {
        $(".left-sidebar").addClass("hide-left-sidebar");
        $(".chat").addClass("show-chatbar");
      }
    });

    $(".dream_profile_menu").on("click", function () {
      $(".right-sidebar").addClass("show-right-sidebar");
      $(".right-sidebar").removeClass("hide-right-sidebar");
      if (
        win_width != undefined &&
        chat_bar != undefined &&
        win_width > 991 &&
        win_width < 1201
      ) {
        $(".chat:not(.right-sidebar .chat)").css("margin-left", -chat_bar);
      }
      if (win_width != undefined && win_width < 992) {
        $(".chat:not(.right-sidebar .chat)").addClass("hide-chatbar");
      }
    });

    jQuery(".close_profile").on("click", function () {
      console.log("sd");
      $(".right-sidebar").addClass("hide-right-sidebar");
      $(".right-sidebar").removeClass("show-right-sidebar");
      if (win_width != undefined && win_width > 991 && win_width < 1201) {
        $(".chat").css("margin-left", 0);
      }
      if (win_width != undefined && win_width < 992) {
        $(".chat").removeClass("hide-chatbar");
      }
    });
    $(".nav-tabs a").on("click", function () {
      $(this).tab("show");
    });

    $(".chat-header .left_side i, .page-header .left_side i").on(
      "click",
      function () {
        $(".left-sidebar").removeClass("hide-left-sidebar");
        $(".chat").removeClass("show-chatbar");
      }
    );
    //Rightside accordian
    $(".accordion-col .accordion-title").on("click", function () {
      $(this).next().slideToggle();
      $(this).toggleClass("active");
    });
    //Custom modal click for status view
    $('*[data-target="#status-modal"]').on("click", function () {
      $("body").addClass("custom-model-open");
    });
    $(".custom-status-close").on("click", function () {
      $("body").removeClass("custom-model-open");
    });

    //Chat Search Visible
    $(".chat-search-btn").on("click", function () {
      $(".chat-search").addClass("visible-chat");
    });
    $(".close-btn-chat").on("click", function () {
      $(".chat-search").removeClass("visible-chat");
    });
    $(".chat-search .form-control").on("keyup", function () {
      var value = $(this).val();
      if (value != undefined) {
        // value=value.toLowerCase();
        /*$(".chat .chat-body .messages .chats").filter(function () {
                    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
                });*/
      }
    });
  }

  @Watch("agent")
  async getProject(): Promise<any> {
    const businessStatuses: BusinessStatus[] = [];
    if (this.agent.projects) {
      this.agent.projects.forEach((project: any) => {
        let projectId = project._id != undefined ? project._id : project;

        Axios(`${BASE_URL_MANAGER}/project/${projectId}`).then((response) => {
          this.project = response.data;
          response.data.bussinesStatusList.forEach((element: BusinessStatus) =>
            businessStatuses.push(element)
          );
        });
      });
    }
    this.businessStatuses = businessStatuses;
  }

  resolveResultConversation(conversation: any): void {
    if (conversation!.state === "open") {
      this.currentList = 2;
    } else if (conversation!.state === "close") {
      this.currentList = 3;
    } else {
      this.currentList = 1;

      conversation = conversation!.conversation;
      delete conversation!.state;
      this.grebConversation(conversation);
      return;
    }
    delete conversation!.state;
    this.getConversation(conversation);
  }

  async loadAgent(): Promise<any> {
    //store.commit(userMutationsTypes.setLoading(true));
    await store.dispatch(
      storeTypes.agent.actions.loadAgent(store.state.user!.user._id)
    );
  }

  loading(): any {
    return store.state.user!.loading;
  }

  get FullName(): string {
    const firstName =
      this.user && this.user.name && this.user.name.firstName
        ? this.user.name.firstName
        : "";
    const lastName =
      this.user && this.user.name && this.user.name.lastName
        ? this.user.name.lastName
        : "";
    return `${firstName} ${lastName}`;
  }

  get user(): any {
    return store.state.user!.user;
  }

  get agent(): any {
    return store.state.agent!.agent;
  }

  get agentOpenConversations(): any {
    return store.state.agent!.agentOpenConversations;
  }

  get currentCloseConversations(): any {
    return store.state.agent!.agentCloseConversations.filter(
      (conversation) =>
        conversation.currentConsumer ===
        store.state.conversation!.currentConversation.currentConsumer
    );
  }

  get agentCloseConversations(): any {
    return store.state.agent!.agentCloseConversations;
  }

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }

  get agentQueue(): any {
    return _.cloneDeep(store.state.agent!.agentQueue).filter(
      (conversation: ConversationNewEntryQueue) =>
        conversation.conversation && conversation.conversation.updatedAt
    );
    /*  .sort((a: ConversationNewEntryQueue, b: ConversationNewEntryQueue) => {
                return new Date(a.conversation!.updatedAt as any).getTime() - new Date(b.conversation!.updatedAt as any).getTime()
            })*/
  }

  get error(): any {
    return store.state.conversation!.error;
  }

  get isReturnConversation(): Conversation | null {
    return store.state.conversation!.conversationReturn;
  }

  get agentConversations(): any {
    let conversations = this.agentOpenConversations.concat(
      Array.from(this.agentCloseConversations).reverse()
    );

    conversations = conversations.filter(
      (conversation: any) => !conversation.isClosed
    );

    return _.uniqBy(conversations, "currentConsumer").filter(
      (conversation: any) => conversation.updatedAt
    );
    /* .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())*/
  }

  get completeConversations(): any {
    const openNumbers: any[] = [];
    const conversations: any[] = [];

    this.agentOpenConversations
      .concat(Array.from(this.agentCloseConversations).reverse())
      .forEach((conversation: any) => {
        if (!conversation.isClosed) {
          openNumbers.push(conversation.currentConsumer);
        } else if (!openNumbers.includes(conversation.currentConsumer)) {
          conversations.push(conversation);
        }
      });

    return _.uniqBy(conversations, "currentConsumer").filter(
      (conversation: any) => conversation.updatedAt
    );
    /*  .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())*/
  }

  /**
   * coinversaciones que estan inactivas, quiere decir conversaciones que fueron disparadas desde automatizacione so campañas masivas pero que el cliente no ha conestado
   * TODO: SERIA  interesante ponerles un filtro para saber dede que campaña o autmatizacion entro la conversacion
   */
  get inactiveConversations(): any {
    const openNumbers: any[] = [];
    const conversations: any[] = [];

    this.agentOpenConversations
      .concat(Array.from(this.agentCloseConversations).reverse())
      .forEach((conversation: any) => {
        if (!conversation.isClosed) {
          openNumbers.push(conversation.currentConsumer);
        } else if (!openNumbers.includes(conversation.currentConsumer)) {
          conversations.push(conversation);
        }
      });

    return _.uniqBy(conversations, "currentConsumer").filter(
      (conversation: any) => conversation.updatedAt
    );
    /*  .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())*/
  }

  get filteredCompleteConversations(): Conversation[] {
    let conversations = this.completeConversations;
    this.resolvedActiveFilters = [];
    if (this.resolvedBusinessStatusFilterValue) {
      this.resolvedActiveFilters.push(this.resolvedBusinessStatusFilterValue);
      conversations = conversations.filter((filter: any) => {
        if (this.resolvedBusinessStatusFilterValue === "RESOLVED") {
          return filter.status === this.resolvedBusinessStatusFilterValue;
        } else {
          return (
            filter.bussinesStatus === this.resolvedBusinessStatusFilterValue
          );
        }
      });
    }
    return conversations;
  }
  get filteredInactiveConversations(): Conversation[] {
    let conversations = this.completeConversations;
    this.inactivedActiveFilters = [];
    if (this.inacttivedBusinessStatusFilterValue) {
      this.inactivedActiveFilters.push(
        this.inacttivedBusinessStatusFilterValue
      );
      conversations = conversations.filter((filter: any) => {
        if (this.inacttivedBusinessStatusFilterValue === "RESOLVED") {
          return filter.status === this.inacttivedBusinessStatusFilterValue;
        } else {
          return (
            filter.bussinesStatus === this.inacttivedBusinessStatusFilterValue
          );
        }
      });
    }
    return conversations;
  }

  @Watch("currentConversation")
  get scrollToEnd(): boolean {
    const element = document.getElementById("conversation");
    if (element) {
      return element.scrollHeight - element.scrollTop === element.clientHeight;
    }
    return false;
  }

  created(): void {
    this.EventBus.$on("image-modal", (values: any) => {
      setTimeout(() => this.enable(this.formatedFileName), 500);
      this.formatedFileName = values.formatedFileName;
      this.dataFileUrl = values.dataFileUrl;
    });

    this.unsubscribe = store.subscribe((mutation) => {
      if (mutation.type === "setAgent") {
        this.retrieveQuickResponses();
        this.currentAgentId = this.agent._id;
      }
    });
  }

  beforeDestroy(): void {
    this.unsubscribe();
  }

  retrieveQuickResponses(): void {
    this.retrieveAgentQuickResponses();
    this.retrieveTopicQuickResponses();
  }

  retrieveAgentQuickResponses(): void {
    let url = `${BASE_URL_MANAGER}/quickResponse/${this.agent._id}`;
    Axios.get(url).then((res) => {
      if (res !== undefined) {
        this.agentQuickResponses = res.data;
      } else {
        this.agentQuickResponses = [];
      }
    });
  }

  retrieveTopicQuickResponses(): void {
    let actualAgent = store.state.agent!.agent;
    let project = store.state.agent!.agent.subscriptions[0].split("_")[0];
    let projectTopics = [];
    let suscribedTopics: any = [];
    let userSubscriptions: any = [];

    let url = `${BASE_URL_MANAGER}/topic/${project}`;
    let urlQuickResponses = "";

    this.topicQuickResponses = [];

    Axios.get(url).then((res) => {
      projectTopics = res.data.projectTopics;

      actualAgent.subscriptions.forEach((sub) => {
        let splitedSub = sub.split("_");
        splitedSub.shift();
        userSubscriptions.push(splitedSub.join("_"));
      });

      projectTopics.forEach((projectTopic: any) => {
        userSubscriptions.forEach((subscription: any) => {
          if (subscription === projectTopic.name) {
            suscribedTopics.push(projectTopic._id);
          }
        });
      });

      let topicQuickResponsesId: any = [];
      suscribedTopics.forEach((topic: any) => {
        urlQuickResponses = `${BASE_URL_MANAGER}/quickResponse/topic/${topic}`;
        Axios.get(urlQuickResponses).then((res) => {
          if (res !== undefined) {
            res.data.forEach((quickResponse: any) => {
              if (!topicQuickResponsesId.includes(quickResponse._id)) {
                topicQuickResponsesId.push(quickResponse._id);
                this.topicQuickResponses.push(quickResponse);
              }
            });
          } else {
            this.topicQuickResponses = [];
          }
        });
      });
    });
  }

  deleteQuickResponse(values: any): void {
    let quickResponseId = values.quickResponseId;
    let url = `${BASE_URL_MANAGER}/quickResponse/${quickResponseId}`;
    Axios.delete(url).then((res) => {
      if (res.status == 200) {
        this.$notification.success({
          placement: "bottomRight",
          message: "Respuesta rápida eliminada exitosamente",
          description: "Los datos han sido eliminados",
        });
      } else {
        this.$notification.error({
          placement: "bottomRight",
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
      this.retrieveQuickResponses();
    });
  }

  getTopic(item: ConversationNewEntryQueue): string {
    return this.cleanTopic(
      item.topic ? item.topic : item.currentTopic ? item.currentTopic : ""
    );
  }

  getFilterTopic(item: string): string {
    return this.cleanTopic(item);
  }

  cleanTopic(topic: string): string {
    let topicElements: any[] = [];
    if (topic.includes("_")) {
      topicElements = topic.split("_");
      topic = "";
      for (let i = 2; i < topicElements.length; i++) {
        topic = topic + topicElements[i] + " ";
      }
      topic.trim();
    }
    return topic;
  }

  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : "";
    if (consumer.includes("@")) consumer = consumer.split("@")[0];
    return consumer;
  }

  getUserName(item: any): any {
    let userName = "";
    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
    }
    return userName;
  }

  getUserPicture(item: any): any {
    let profileurl = null;

    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }

    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_PROFILE_PICTURE
    ) {
      profileurl = conversation.variables.CONSUMER_PROFILE_PICTURE.value;
    }

    return profileurl;
  }

  async closeConversation(): Promise<any> {
    await store.dispatch(storeTypes.conversation.actions.closeConversation());
    await this.loadAgent();
    this.currentList = 3;
  }

  get realDisconnectedStatus(): number {
    return this.webSocketService.disconnectedStatus;
  }

  @Watch("realDisconnectedStatus")
  isDisconnected() {
    if (this.webSocketService.disconnectedStatus > 0 && this.alertIsOpen) {
      Swal.close();
      this.alertIsOpen = false;
    }
  }

  get interactions(): Interaction[] {
    return store.state.agent!.interactions;
  }

  @Watch("interactions")
  downScroll(): void {
    const element = document.getElementById("chat-content-agent");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  grebConversation(conversation: any): void {
    setTimeout(() => {
      $("body").removeClass("swal2-height-auto");
    }, 75);
    // const item = this.agentQueue.find((queue: ConversationNewEntryQueue) => queue.conversation._id === conversation._id);
    let canGrabTheConversation = true;
    this.webSocketService.listen(
      `/${conversation.currentTopic}`,
      SOCKET_EVENTS.REMOVE_ENTRY,
      (entry) => {
        if (entry._id === conversation._id) {
          canGrabTheConversation = false;
        }
      }
    );

    this.alertIsOpen = true;

    Swal.fire({
      title: "Conversación seleccionada",
      icon: "info",
      html: "¿Desea tomar la conversación?",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: "Aceptar",
    }).then(async (result) => {
      this.alertIsOpen = false;
      if (result.value) {
        if (canGrabTheConversation) {
          let quickResponseText = `Hola, soy ${this.FullName}, es un placer atenderte el dia de hoy.`;
          if ((conversation as any).status === "TRANSFERRED") {
            quickResponseText = `La conversación ha sido transferida a ${this.FullName}`;
          }
          //TODO, ESTO TIENE QUE SER PARAMETRIZABLE, SI EL PROYECTO QUIERE O NO QUE SE LE ENVIE EL MENSAJE
          store
            .dispatch(
              storeTypes.conversation.actions.grebConversation(conversation)
            )
            .then(async (response) => {
              if (response) {
                this.currentList = 2;

                console.log(store.state!.agent!.agentQueuePerPage);
                console.log(store.state!.agent!.agentQueueTotal);

                // si la cola es menor a nueve refresco por si acasoa nueva conversacion que n o se esta mostrando hago que aparezca otra v ez

                if (
                  store.state!.agent!.agentQueueTotal <=
                  store.state!.agent!.agentQueuePerPage
                ) {
                  store.dispatch(
                    storeTypes.agent.actions.loadAgentQueues({
                      subscriptions: store.state!.agent!.agent.subscriptions,
                      page: store.state!.agent!.actualPage,
                      restartQueue: true,
                    })
                  );
                }
                // await this.sendQuickResponse([quickResponseText])
              } else {
                this.fireSwalGrabError();
              }
            })
            .catch(this.fireSwalGrabError);
        } else {
          this.fireSwalGrabError();
        }
      }
    });
  }

  async transferConversationToAgent(fullName: string): Promise<void> {
    await this.sendQuickResponse([
      `La conversación ha sido transferida a ${fullName}`,
    ]);
  }

  async fireSwalGrabError(): Promise<void> {
    setTimeout(() => {
      $("body").removeClass("swal2-height-auto");
    }, 75);
    await Swal.fire({
      icon: "error",
      title: "Ha ocurrido un problema",
      text: "Alguien mas tomo esta conversación antes que tu",
    });
  }

  getConversation(item: Conversation): void {
    this.$root.$emit("closeReply", true);
    this.objectConversationUpdate = item;
    store.commit(
      storeTypes.conversation.mutations.setCurrentConversation(item)
    );
  }

  get transferredToAgent(): { agent: any; conversation: Conversation } | null {
    return store.state.agent!.transferredToAgent;
  }

  getInitials(currentConversation: any): string {
    if (
      currentConversation &&
      currentConversation.variables != undefined &&
      currentConversation.variables.CONSUMER_NAME != undefined &&
      currentConversation.variables.CONSUMER_NAME.value != undefined
    ) {
    }
    const firstName =
      currentConversation &&
      currentConversation.variables != undefined &&
      currentConversation.variables.CONSUMER_NAME != undefined &&
      currentConversation.variables.CONSUMER_NAME.value != undefined &&
      !isNaN(currentConversation.variables.CONSUMER_NAME)
        ? currentConversation.variables.CONSUMER_NAME.value
            .substr(0, 1)
            .toUpperCase()
        : "";
    const lastName =
      currentConversation &&
      currentConversation.variables != undefined &&
      !isNaN(currentConversation.variables.CONSUMER_NAME)
        ? currentConversation.variables.CONSUMER_NAME.value
            .substr(1, 1)
            .toUpperCase()
        : "";

    return `${firstName}${lastName}`;
  }

  @Watch("transferredToAgent")
  async conversationTransferred(): Promise<void> {
    if (this.transferredToAgent) {
      const name = this.getUserName(this.transferredToAgent!.conversation)
        ? this.getUserName(this.transferredToAgent!.conversation)
        : this.getConsumer(this.transferredToAgent!.conversation);
      const agentName = await this.getAgentFullName(
        this.transferredToAgent!.agent.user
      );
      this.$notification.success({
        placement: "TopRight",
        message: "Conversación transferida",
        description: `${agentName} te ha transferido la conversación de ${name}`,
      });
    }
  }

  getAgentFullName(userId: string): Promise<string> {
    return new Promise(async (resolve) => {
      await Axios.get(`${BASE_URL_MANAGER}/user/${userId}`).then(
        (response: AxiosResponse) =>
          resolve(
            `${response.data.user.name.firstName} ${response.data.user.name.lastName}`
          )
      );
    });
  }

  @Watch("currentConversation")
  async getConversationUpdate(): Promise<any> {
    await this.getProject();
    //await store.commit(storeTypes.agent.mutations.setInteractions([]));
    await store.commit(storeTypes.agent.mutations.setActualPage(0));
    await store.commit(storeTypes.agent.mutations.setTotalPages(0));
    if (this.currentConversation && this.currentConversation._id) {
      await this.chargeInteractions(this.actualPage);
    }
  }

  async chargeInteractions(page: number): Promise<any> {
    //lo comentamos temporalmente porque detectamos que es innecesario ya que carga dobloemente las interacciones
    // await this.loadInteractions(page);
    this.maxHeightSubstract = this.currentConversation.isClosed ? 0 : 160;
    setTimeout(
      () =>
        this.$nextTick(
          () =>
            (this.$el.scrollTop = this.$el.scrollHeight - this.$el.clientHeight)
        ),
      2000
    );
  }

  async loadInteractions(page: number): Promise<any> {
    await store.dispatch(
      storeTypes.agent.actions.loadActualInteractions({
        conversationId: this.currentConversation._id,
        page,
      })
    );
  }

  async sendQuickResponseMessage(send: string): Promise<any> {
    console.log(send);

    return new Promise(async (resolve) => {
      const currentTextMessage: any = [send];
      const idTextMessage = Math.random().toString(36).substr(2, 9);
      this.EventBus.$emit("sendingMessage", idTextMessage);
      await store.dispatch(
        storeTypes.conversation.actions.sendMessage({
          id: idTextMessage,
          type: MessageType.MESSAGE,
          text: currentTextMessage,
          origin: InteractionOrigin.RSP,
        })
      );
      this.EventBus.$emit("sentMessage", idTextMessage);
      resolve(true);
    });
  }

  async sendQuickResponseFile(
    send: any,
    fileName: any,
    fileType: any
  ): Promise<any> {
    console.log("fileName", fileName);

    const currentFileUrl: string = send;
    const idTextMessage = Math.random().toString(36).substr(2, 9);
    //fileName = fileName.split('/')[1].split('_')[1];
    this.EventBus.$emit("sendingMessage", idTextMessage);
    if (fileType === "image") {
      await store.dispatch(
        storeTypes.conversation.actions.sendMessage({
          id: idTextMessage,
          type: MessageType.IMAGE,
          fileurl: currentFileUrl,
          filename: fileName,
          origin: InteractionOrigin.RSP,
        })
      );
    } else if (fileType === "file") {
      await store.dispatch(
        storeTypes.conversation.actions.sendMessage({
          id: idTextMessage,
          type: MessageType.FILE,
          fileurl: currentFileUrl,
          filename: fileName,
          origin: InteractionOrigin.RSP,
        })
      );
    }
    this.EventBus.$emit("sentMessage", idTextMessage);
  }

  async sendQuickResponse(values: any): Promise<any> {
    const message = values.message;
    if (values.fileName == "") {
      this.sendQuickResponseMessage(message);
    } else {
      const url = values.url;
      if (url == "") {
        this.sendQuickResponseMessage(message);
      } else {
        this.sendQuickResponseMessage(message).then(() => {
          this.sendQuickResponseFile(url, values.fileName, values.fileType);
        });
      }
    }
  }
  changeTab(e: any) {
    this.currentTab = e;
  }

  enable(id: string): void {
    $(`#${id}`).modal("show");
  }

  disable(id: string): void {
    $(`#${id}`).modal("hide");
  }

  resultConversationToActive(item: any): void {
    setTimeout(() => {
      $("body").removeClass("swal2-height-auto");
    }, 75);
    Swal.fire({
      title: "Conversación reactivable",
      icon: "info",
      html: `¿Deseas reactivar la conversación con <b>${item.variables.CONSUMER_NAME.value}</b>?<br> <small class="text-info">(Se le enviara un mensaje de confirmación)</small>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: "Aceptar",
    }).then(async (result) => {
      if (result.value) {
        await store.dispatch(
          storeTypes.conversation.actions.returnConversation(item._id)
        );
      }
    });
  }

  @Watch("isReturnConversation")
  setOpenList() {
    if (this.isReturnConversation) {
      this.currentList = 2;
      this.$notification.open({
        placement: "topRight",
        message: "Conversación reactivada!",
        description: `El usuario ${this.getUserName(
          this.isReturnConversation
        )} ha reactivado la conversación!`,
        icon: "info",
      });
    }
  }

  /**
   * abre el dialogo de conversacion para inciar ocnversacion con clientes que no han inciado conversacion con nosotros
   */
  openStartConversationDialog(): void {
    console.log("this.templates", this.templates);

    if (this.templates.length == 0) {
      this.getTemplates();
    }
    let modal: any = $("#start-conversation");
    modal.modal("show");
  }

  isInvalidValidStrartConfersationForm() {
    if (
      this.startConverstionObject.text == undefined ||
      this.startConverstionObject.text == ""
    ) {
      return true;
    }
    if (
      this.startConverstionObject.phone == undefined ||
      this.startConverstionObject.phone == ""
    ) {
      return true;
    }
    if (
      this.startConverstionObject.template == undefined ||
      this.startConverstionObject.template == ""
    ) {
      return true;
    }

    return false;
  }
  /**
   * inicia la conversacion con un cliente nuevo
   */
  async startConversation() {
    try {
      let channel = "";

      let node: any = {
        type: "TEXT",
        text: [this.startConverstionObject.text],
      };
      let areaCode: any = "";
      switch (this.project.country) {
        case "CO":
          areaCode = 57;
          break;
        case "MX":
          areaCode = 52;
          break;
        case "PA":
          areaCode = 507;
          break;
        default:
          areaCode = 57;
          break;
      }
      //todo:guardar esto
      switch (this.project!.integrator) {
        case "DROPI":
          channel = "WHATSAPP_CLOUD";
          break;
        case "MESSAGEBIRD":
          channel = "WHATSAPP_MESSAGEBIRD";
          break;
        default:
          channel = "WHATSAPP_CLOUD";
          break;
      }
      this.startConverstionObject.phone = this.startConverstionObject.phone
        .split(" ")
        .join("");

      let respMessage = await Axios.post(
        `${BASE_URL_WHATSAPP_CLOUD}/user/${
          areaCode + this.startConverstionObject.phone
        }`,
        {
          message: node,
          idmessase: null,
          isForwarded: false,
          idreference: null,
          conversation_id: null,
          apiKey: this.project.wpBusinessAPiKey,
          phoneNumberId: this.project.phoneNumberId,
          wabaId: this.project.wabaId,
          template: this.startConverstionObject.template,
        }
      );

      let dataMessage = respMessage!.data!.data;

      let data = {
        nodo: node,
        conversation: {
          project: this.project._id,
          currentChannel: channel,
          consumer: [areaCode + this.startConverstionObject.phone],
          currentConsumer: areaCode + this.startConverstionObject.phone,
          topics: this.$store.state.agent.agent.subscriptions,
          show_in_queue: true,
        },
        msgidentity: { idmessage: dataMessage!.resp!.data!.messages[0]!.id },
        consumerName: this.startConverstionObject.name,
      };

      let url = `${BASE_URL_ORCHESTATOR}/conversation/interaction/output`;
      let respConversation = await Axios.post(url, data);

      let conversation = respConversation!.data!.conversation;

      conversation.currentConsumer =
        areaCode + this.startConverstionObject.phone;

      if (respConversation.status == 200) {
        store.commit(
          conversationMutationsTypes.setCurrentConversation(conversation)
        );

        //this.grebConversation(conversation);

        Axios(
          `${BASE_URL_ORCHESTATOR}/agent/${
            this.$store.state.agent.agent._id
          }/conversation/grab/${conversation!._id}`
        )
          .then((res) => {
            console.log("response", res);
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            let modal: any = $("#start-conversation");
            modal.modal("hide");
            this.startConverstionObject = {};
          });
      } else {
        this.$notification.error({
          placement: "bottomRight",
          message: `Hubo un error al guardar al información ${respConversation.status}`,
          description: `Error: ${respConversation.status}`,
        });
      }
    } catch (e) {
      console.log(e);

      this.$notification.error({
        placement: "bottomRight",
        description: `Hubo un error al guardar al información`,
        message: `Error:`,
      });
    }
  }

  getTemplates() {
    Axios.get(BASE_URL_MANAGER + "/templates", {
      params: {
        project_id: this.project._id,
      },
    }).then(async (res) => {
      if (res.status == 200) {
        this.templates = res!
          .data!.templates.map((t: any) => {
            const dontHaveParams = t.components.filter(
              (e: any) =>
                e.type == "BODY" &&
                e.text != undefined &&
                e!.text.indexOf("{") < 0
            );
            return {
              ...t,
              dontHaveParams,
            };
          })
          .filter((t: any) => {
            return t.dontHaveParams.length > 0 /*&& t.status === 'APPROVED'*/;
          });
      } else {
        this.$notification.error({
          placement: "bottomRight",
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
      this.retrieveQuickResponses();
    });
  }

  async onChangeTemplate(e: any) {
    // this.startConverstionObject.template = e;
    let body = await this.startConverstionObject.template.components.find(
      (e: any) => e.type == "BODY"
    );
    console.log(body);
    this.startConverstionObject.text = await body.text;
  }
}
