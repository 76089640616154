import { VueWizard } from "@/vue-wizard";
import { Component } from "vue-property-decorator";
import { Prop, Watch } from 'vue-property-decorator';
import Axios from "axios";
import { BASE_URL_MANAGER } from "@/config";
import * as bootstrap from "bootstrap"
import $ from 'jquery';
import CheckBox from '@/app/components/shared/check-box/CheckBox.vue'

@Component({
    name: 'new-quick-response-modal',
    components: { CheckBox }
})
export default class NewQuickResponseModal extends VueWizard {
    @Prop() readonly elementId!: string;
    @Prop({ default: true }) readonly autoDisable!: boolean;
    @Prop() readonly parentElementId!: string;
    @Prop() readonly topics!: any;
    @Prop() readonly mode!: string;
    @Prop() readonly agentId!: string;
    @Prop() EventBus !: any;

    public messages = '';
    public title = '';
    public filesToUpload: any = [];
    public uploadedSuccesful: boolean = true;
    public fileName: string = '';
    public inputType: string = '';
    public loading :boolean = false;

    public topicQuickResponse: any = {
        topic: [],
        messages: [],
        title: '',
        url: ''
    }

    public agentQuickResponse: any = {
        agent: '',
        messages: [],
        title: '',
        url: ''
    }

    created(){
        this.EventBus.$on('reload-modal', ()=>{
            this.cleanComponent();
        })
    }

    disable() {
        if (this.autoDisable) {
            $('#' + this.elementId).modal('hide');
        } else {
            this.elementDisableEnable('#' + this.elementId, '#' + this.parentElementId)
        }
    }

    elementDisableEnable(elementId: string, enableId: any): void {
        let modal: any = $(elementId);
        modal.modal('hide');
        modal = $(enableId);
        modal.modal('show');
    }

    verifyTopics() {
        if (this.topicQuickResponse.topic.length > 0) {
            return true;
        }
        return false;
    }

    async saveTopicQuickResponse() {
        this.topicQuickResponse.messages.push(this.messages);

        this.topicQuickResponse.title = this.title;

        let url = `${BASE_URL_MANAGER}/quickresponse/topic`;

        this.handleUpload().then(() => {

            this.topicQuickResponse.messages.push(this.fileName);
            this.topicQuickResponse.messages.push(this.inputType);

            if (this.verifyTopics() && this.uploadedSuccesful) {
                Axios.post(url, this.topicQuickResponse).then(res => {
                    this.loading = false;
                    this.$emit('saved');
                    if (res.status == 200) {
                        this.$notification.success({
                            placement: "bottomRight",
                            message: "Respuesta rápida guardada exitosamente",
                            description: "Los datos han sido guardados"
                        });
                    } else {
                        this.$notification.error({
                            placement: "bottomRight",
                            message: `Hubo un error al guardar al información ${res.status}`,
                            description: `Error: ${res.status}`
                        })
                    }
                })
                this.cleanComponent();
                this.disable();

            } else {
                this.$notification.error({
                    placement: "bottomRight",
                    message: `Tiene que seleccionar mínimo un tópico`,
                    description: `Error al guardar al información`
                })
            }

        });


    }

    async saveAgentQuickResponse() {
        this.agentQuickResponse.messages.push(this.messages);

        this.agentQuickResponse.title = this.title;
        this.agentQuickResponse.agent = this.agentId;

        let url = `${BASE_URL_MANAGER}/quickresponse`;

        this.handleUpload().then(() => {


            this.agentQuickResponse.messages.push(this.fileName);
            this.agentQuickResponse.messages.push(this.inputType);

            if (this.uploadedSuccesful) {
                Axios.post(url, this.agentQuickResponse).then(res => {
                    this.loading = false;
                    this.$emit('saved');
                    if (res.status == 200) {
                        this.$notification.success({
                            placement: "bottomRight",
                            message: "Respuesta rápida guardada exitosamente",
                            description: "Los datos han sido guardados"
                        });
                    } else {
                        this.$notification.error({
                            placement: "bottomRight",
                            message: `Hubo un error al guardar al información ${res.status}`,
                            description: `Error: ${res.status}`
                        })
                    }
                });
                this.cleanComponent();
                this.disable();
            }
        });
    }

    cleanComponent() {
        this.topicQuickResponse = {
            topic: [],
            messages: [],
            title: '',
            url: ''
        }
        this.agentQuickResponse = {
            agent: '',
            messages: [],
            title: '',
            url: ''
        }
        this.title = '';
        this.messages = '';
        this.filesToUpload = [];
        this.uploadedSuccesful = true;
        this.loading=false;

        this.$children.forEach((check: any) => {
            check.unCheck();
        })

        if (this.inputType != '') {
            const input: any = this.$refs.fileInput;

            input.type = 'text';
            input.type = 'file';
        }

        this.inputType = '';

    }

    isEmpty(str: string) {
        return str.match(/^ *$/) !== null;
    }

    saveQuickResponse() {

        if (!this.isEmpty(this.messages) && !this.isEmpty(this.title) && this.messages.trim() !== '') {
            if (this.mode === 'topic') {
                this.saveTopicQuickResponse();
            } else if (this.mode === 'agent') {
                this.saveAgentQuickResponse();
            }
        } else {
            this.$notification.error({
                placement: "bottomRight",
                message: `El título o el mensaje no tiene contenido válido`,
                description: `Error al guardar al información`
            })
        }

    }


    handleChange(info: any) {
        console.log('info',info.target.files);
        let filelist = Array.from(info.target.files)
        filelist.forEach((file: any) => {
            this.filesToUpload.push(file);
        });
    }

    handleUpload() {
        this.loading = true;

        return new Promise(resolve => {
            if (this.filesToUpload.length > 0) {
                if (this.filesToUpload[0].size < 40000000) {
                    let formData = new FormData();

                    formData.append('image', this.filesToUpload[0]);

                    let url = `${BASE_URL_MANAGER}/company/fileupload/`

                    let that=this;
                    Axios.post(url, formData,
                        { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                            if (res.data.success) {
                                this.uploadedSuccesful = true;
                                this.topicQuickResponse.url = res.data.urlfile;
                                this.agentQuickResponse.url = res.data.urlfile;
                                this.fileName = res.data.fileName;
                            } else {
                                this.uploadedSuccesful = false;
                            }

                            resolve();
                        }).catch(function (error) {
                            console.log(error.toJSON());
                            that.uploadedSuccesful = false;
                          });;

                } else {
                    this.$message.error(`El archivo ${this.filesToUpload[0].name} es muy grande.`);
                }
            } else {
                this.uploadedSuccesful = true;
                resolve();
            }
        })
    }

    addTopic(id: any) {


        this.topicQuickResponse.topic.push(id);
    }

    deleteTopic(id: any) {

        this.topicQuickResponse.topic = this.topicQuickResponse.topic.filter(function (topic: any) { return topic !== id })
    }

}