import { Component, Prop, Watch } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import { fromEvent, Subscription } from 'rxjs';
import $ from "jquery";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { BASE_URL_MANAGER } from "@/config";
// Components
import LoadBar from "@/app/components/shared/load-bar/LoadBar.vue";
import { store } from "@/app/store";
import { conversationActionsTypes } from "@/app/store/modules/conversation/conversation.actions";
import { timer } from "rxjs";

interface TemporalTransferAgent {
    createdAt: Date;
    disabled: boolean;
    name: {
        firstName: string;
        lastName: string;
    }
    projects: string[];
    subscriptions: string[];
    updatedAt: Date;
    user: string;
    username: string;
    __v: any;
    _id: string;
}

interface TemporalTransferTopic {
    agents: string[];
    name: string;
    project: string;
    __V: any;
    _id: string;
}

@Component({
    name: 'transfer-conversation-modal',
    components: {
        LoadBar
    }
})
export default class TransferConversationModal extends VueWizard {

    @Prop() public readonly projectIds?: string[];

    private modalReference: JQuery<HTMLElement> | null = null;
    private modalHideSubscription: Subscription | null = null;

    public selectedTransferOption = null;
    public step = 1;

    public topics: TemporalTransferTopic[] | null = null;
    public agents: TemporalTransferAgent[] | null = null;

    public loading = false;
    public transferring = false;
    public transferred = false;
    public loadingErrors: AxiosError[] | null = null;

    public selectedTopicIndex: number | null = null;
    public selectedAgentIndex: number | null = null;

    mounted(): void {
        this.modalReference = $('#transfer-conversation-modal');
        this.modalReference.modal('show');
        this.modalHideSubscription = fromEvent(this.modalReference, 'hidden.bs.modal').subscribe(() => {
            this.$emit('modalHideNoConfirm');
            if (this.modalHideSubscription) {
                this.modalHideSubscription.unsubscribe();
            }
            this.reset();
        });
    }

    reset(): void {
        this.step = 1;
        this.selectedTransferOption = null;
        this.topics = null;
        this.agents = null;
        this.loadingErrors = null;
        this.loading = false;
        this.selectedTopicIndex = null;
        this.selectedAgentIndex = null;
        this.transferring = false;
    }

    @Watch('step')
    async onNextStep(): Promise<void> {
        if (this.step === 2) {
            if (this.projectIds && Array.isArray(this.projectIds)) {
                this.loading = true;
                await this.asyncForEach(this.projectIds, async (project: string) => {
                    if (this.selectedTransferOption === 1) {
                        await this.loadProjectTopics(project);
                    } else if (this.selectedTransferOption === 2) {
                        await this.loadProjectTopics(project).then(async () => await this.loadProjectAgents());
                    }
                });
                this.loading = false;
            }
        }
    }

    async loadProjectTopics(project: any): Promise<void> {

        console.log('projectId', project);

        if (!this.topics) {
            this.topics = [];
        }
        await axios.get(`${BASE_URL_MANAGER}/topic/${project._id}`)
            .then((response: AxiosResponse) => {
                if (response && response.data && response.data.projectTopics) {
                    response.data.projectTopics.forEach((topic: any) => {
                        if (this.topics) {
                            this.topics.push(topic)
                        }
                    })
                }
            })
            .catch(this.addLoadingError)
    }

    async loadProjectAgents(): Promise<void> {
        if (!this.agents) {
            this.agents = [];
        }
        const agentIds: string[] = [];
        if (this.topics) {
            this.topics.forEach((topic: any) => {
                if (topic.agents && Array.isArray(topic.agents)) {
                    topic.agents.forEach((agentId: string) => {
                        if (!agentIds.includes(agentId) && store.state.agent && agentId !== store.state.agent.agent._id) {
                            agentIds.push(agentId)
                        }
                    })
                }
            });
        }
        await this.obtainAgentsByIds(agentIds);
        await this.obtainUsersByAgents();
    }

    async obtainUsersByAgents(): Promise<void> {



        if (this.agents) {
            await this.asyncForEach(this.agents, async (agent: any) => {



                if (agent.user && agent.user != null) {
                    await this.getUserById(agent.user).then(

                        (user: any) => {
                            if (user != null) {
                                Object.assign(agent, { username: user.username, name: user.name })
                            }
                        }
                    )
                }
            });
        }
    }

    async obtainAgentsByIds(agentIds: any[]): Promise<void> {
        await this.asyncForEach(agentIds, async (id: string) => {
            await this.getAgentById(id)
                .then((agent: any) => {
                    if (agent && this.agents) {
                        this.agents.push(agent)
                    }
                });
        });
    }

    async asyncForEach(array: any[], callback: (element: any, index: number, array: any[]) => void): Promise<void> {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    async getAgentById(agentId: string): Promise<any> {
        return new Promise(async (resolve) => {
            await axios.get(`${BASE_URL_MANAGER}/agent/${agentId}`)
                .then((response: AxiosResponse) => resolve(response.data.agent))
                .catch(this.addLoadingError)
        });
    }

    async getUserById(userId: string): Promise<any> {
        return new Promise(async (resolve) => {
            await axios.get(`${BASE_URL_MANAGER}/user/${userId}`)
                .then((response: AxiosResponse) => resolve(response.data.user))
                .catch(this.addLoadingError)
        });
    }

    async retryLoad(): Promise<any> {
        this.loadingErrors = null;
        await this.onNextStep();
    }

    addLoadingError(error: AxiosError): void {
        if (!this.loadingErrors) {
            this.loadingErrors = [];
        }
        this.loadingErrors.push(error);
    }

    formatTopicName(name: string): string[] {
        const validElements = name.split('_');
        validElements.splice(0, 1);
        return [name.split('_')[0], validElements.join(' ')];
    }

    agentNameInitials(name: { firstName: string, lastName: string }): string {
      
        
        return name.firstName && name.firstName[0] ? name.firstName[0].toUpperCase() : ''
            + name.lastName && name.lastName[0] ? name.lastName[0].toUpperCase() : '';
    }

    transferConversation(): void {
        if (this.selectedAgentIndex && this.agents && Array.isArray(this.agents)) {
            const agent = this.agents[this.selectedAgentIndex - 1];
            this.$emit('transferredToAgent', `${agent.name.firstName} ${agent.name.lastName}`);
            this.transferConversationToAgent(this.agents[this.selectedAgentIndex - 1]._id)
        } else if (this.selectedTopicIndex && this.topics && Array.isArray(this.topics)) {
            this.transferConversationToSegment(this.topics[this.selectedTopicIndex - 1]._id)
        }
    }

    transferConversationToAgent(agentId: string): void {
        this.transfer(agentId, false);
    }

    transferConversationToSegment(topicId: string): void {
        this.transfer(topicId, true);
    }

    transfer(id: string, isTopic: boolean): void {
        this.step = 3;
        this.transferring = true;
        let action: any = null;
        if (isTopic) {
            action = conversationActionsTypes.transferConversationToSegment(id)
        } else {
            action = conversationActionsTypes.transferConversationToAgent(id)
        }
        timer(5000).subscribe(() => {
            store.dispatch(action).then(() => {
                this.transferring = false;
                this.transferred = true;
            });
        });
    }

    finish(): void {
        if (this.modalReference) {
            this.modalReference.modal('hide');
        }
    }

}