import { render, staticRenderFns } from "./BusinessStatusSelector.vue?vue&type=template&id=74fb4236&scoped=true&"
import script from "./business-status-selector.ts?vue&type=script&lang=ts&"
export * from "./business-status-selector.ts?vue&type=script&lang=ts&"
import style0 from "./BusinessStatusSelector.vue?vue&type=style&index=0&id=74fb4236&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74fb4236",
  null
  
)

export default component.exports