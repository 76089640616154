import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import {
  Conversation,
  MessageType,
  Interaction,
  InteractionOrigin,
  IError,
} from "@/app/store/modules/conversation/conversation.models";
import { store, storeTypes } from "@/app/store";
import Axios from "axios";
import { agentMutationsTypes } from "@/app/store/modules/agent/agent.mutations";

import {
  BASE_URL_MANAGER,
  BASE_URL_ORCHESTATOR,
  BASE_URL_WHATSAPP_CLOUD,
} from "@/config";
import allEmojis from "@/app/models/emojis";
import { fromEvent } from "rxjs";
import ConversationQuickResponse from "@/app/components/conversation/conversation-quick-response/ConversationQuickResponse.vue";
import $ from "jquery";

import ModalComponent from "@/app/components/modal/ModalComponent.vue";
import { conversationMutationsTypes } from "@/app/store/modules/conversation/conversation.mutations";
import Swal from "sweetalert2";
import eventBus from "@/main";

@Component({
  name: "conversation-form-send",
  components: { ConversationQuickResponse, ModalComponent },
})
export default class ConversationFormSend extends VueWizard {
  @Prop() readonly currentConversation!: Conversation;
  @Prop() readonly spinConversation!: boolean;
  @Prop() readonly EventBus!: Vue;
  @Prop() readonly agentQuickResponses!: any;
  @Prop() readonly topicQuickResponses!: any;
  @Prop() readonly currentAgentId!: any;

  public emojis = [];
  public bus: Vue = new Vue();
  public message = "";
  public messageSend = "";
  public emogiSearch = "";
  public imageList: any[] = [];
  public originalFileOrder: any[] = [];
  public originalImageOrder: any[] = [];
  public fileList: any[] = [];
  public fileListEmpty: any[] = [];

  public limitAlert = false;

  public audioList: any[] = [];
  public audio: any = null;
  public imageListempty: any[] = [];
  public uploading = false;
  public visiableAttachmentForm = false;
  public visibleEmojis = false;
  public previewVisible = false;
  public visibleQuickResponses = false;
  public previewImage = "";
  public toUploadFiles = 0;
  public uploadedFiles = 0;

  public emojiCategories: string[] = [];
  public actualCategory = "";
  public audioSender = false;
  public replyText: string = "";
  public replyData: Object | any = {};
  public replyContainer: boolean = false;
  public errorToast: any;
  public imageUrl: any = "";
  public loading: boolean = false;
  public isAgent?: boolean;
  public sendingMessage: boolean = false;
  public URL_ORCHESTATOR = BASE_URL_ORCHESTATOR;
  public isError: boolean = true;
  public isModalVisible: boolean = false;
  public project: any;

  public templates: any = [];
  public startConverstionObject: any = {
    phone: store.state.conversation!.currentConversation.currentConsumer,
    name: "",
    text: "",
  };
  // MODAL
  openStartConversationDialog(): void {
    if (this.templates.length == 0) {
      this.getTemplates();
    }
    //   console.log(this.templates);

    //   let modal: any = $("#start-conversation");
    //   console.log("MODAl", modal);

    //   modal.modal("show");
  }
  async startConversation() {
    try {
      let channel = "";

      let node: any = {
        type: "TEXT",
        text: [this.startConverstionObject.text],
      };
      switch (this.project!.integrator) {
        case "DROPI":
          channel = "WHATSAPP_CLOUD";
          break;
        case "MESSAGEBIRD":
          channel = "WHATSAPP_MESSAGEBIRD";
          break;
        default:
          channel = "WHATSAPP_CLOUD";
          break;
      }

      console.log(
        "this.$store.state.agent.agent",
        this.$store.state.agent.agent
      );

      this.startConverstionObject.phone = this.startConverstionObject.phone
        .split(" ")
        .join("");

      let respMessage = await Axios.post(
        `${BASE_URL_WHATSAPP_CLOUD}/user/${this.startConverstionObject.phone}`,
        {
          message: node,
          idmessase: null,
          isForwarded: false,
          idreference: null,
          conversation_id: null,
          apiKey: this.project.wpBusinessAPiKey,
          phoneNumberId: this.project.phoneNumberId,
          wabaId: this.project.wabaId,
          template: this.startConverstionObject.template,
        }
      );

      let dataMessage = respMessage!.data!.data;

      let data = {
        nodo: node,
        conversation: {
          project: this.project._id,
          currentChannel: channel,
          consumer: [this.startConverstionObject.phone],
          currentConsumer: this.startConverstionObject.phone,
          topics: this.$store.state.agent.agent.subscriptions,
          show_in_queue: true,
        },
        msgidentity: { idmessage: dataMessage!.resp!.data!.messages[0]!.id },
        consumerName: this.startConverstionObject.name,
      };

      let url = `${BASE_URL_ORCHESTATOR}/conversation/interaction/output`;
      let respConversation = await Axios.post(url, data);

      let conversation = respConversation!.data!.conversation;

      conversation.currentConsumer = this.startConverstionObject.phone;

      if (respConversation.status == 200) {
        store.commit(
          conversationMutationsTypes.setCurrentConversation(conversation)
        );

        //this.grebConversation(conversation);

        Axios(
          `${BASE_URL_ORCHESTATOR}/agent/${
            this.$store.state.agent.agent._id
          }/conversation/grab/${conversation!._id}`
        )
          .then((res) => {
            console.log("response", res);
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            let modal: any = $("#start-conversation");
            modal.modal("hide");
            this.startConverstionObject = {};
            this.isModalVisible = false;
          });
      } else {
        this.$notification.error({
          placement: "bottomRight",
          message: `Hubo un error al guardar al información ${respConversation.status}`,
          description: `Error: ${respConversation.status}`,
        });
      }
    } catch (e) {
      console.log(e);

      this.$notification.error({
        placement: "bottomRight",
        description: `Hubo un error al guardar al información`,
        message: `Error:`,
      });
    }
  }
  async getProject(idProject: string) {
    let resp = await Axios.get(`${BASE_URL_MANAGER}/project/${idProject}`);
    console.log("getProject", resp);

    if (resp.status == 200) {
      return resp.data.project;
    }
  }
  async getTemplates() {
    let projectID = store.state.conversation!.currentConversation.project;
    this.project = await this.getProject(projectID!);
    Axios.get(BASE_URL_MANAGER + "/templates", {
      params: {
        project_id: this.project._id,
      },
    }).then(async (res) => {
      if (res.status == 200) {
        this.templates = res!
          .data!.templates.map((t: any) => {
            const dontHaveParams = t.components.filter(
              (e: any) =>
                e.type == "BODY" &&
                e.text != undefined &&
                e!.text.indexOf("{") < 0
            );
            return {
              ...t,
              dontHaveParams,
            };
          })
          .filter((t: any) => {
            return t.dontHaveParams.length > 0 /*&& t.status === 'APPROVED'*/;
          });
      } else {
        this.$notification.error({
          placement: "bottomRight",
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
    });
  }

  isInvalidValidStrartConfersationForm() {
    this.startConverstionObject.phone =
      store.state.conversation!.currentConversation.currentConsumer;
    if (
      this.startConverstionObject.text == undefined ||
      this.startConverstionObject.text == ""
    ) {
      return true;
    }
    if (
      this.startConverstionObject.phone == undefined ||
      this.startConverstionObject.phone == ""
    ) {
      return true;
    }
    if (
      this.startConverstionObject.template == undefined ||
      this.startConverstionObject.template == ""
    ) {
      return true;
    }

    return false;
  }

  async onChangeTemplate(e: any) {
    // this.startConverstionObject.template = e;
    let body = await this.startConverstionObject.template.components.find(
      (e: any) => e.type == "BODY"
    );
    console.log(body);
    this.startConverstionObject.text = await body.text;
  }

  // ENDMODAL

  showAudioSender(): void {
    this.audioSender = !this.audioSender;
    this.visibleQuickResponses = false;
    this.visibleEmojis = false;
    this.imageList = [];
    this.fileList = [];
  }
  mounted(): void {
    eventBus.$on("openModal", () => {
      this.isModalVisible = true;
      this.openStartConversationDialog();
    });
    this.isError = true;
    this.emojiCategories = Object.keys(allEmojis);
    this.getEmojis(this.emojiCategories[0]);
    fromEvent(document, "paste").subscribe((event: any) => this.onPaste(event));
    let self = this;
    this.$root.$on("closeReply", function (changeConversation: boolean) {
      if (changeConversation) self.replyData = {};
    });
    this.$root.$on(
      "sendReply",
      async function (reply: Interaction, currentUser: boolean) {
        const element: any = <Vue>self.$refs["input"];
        if (element && typeof element !== undefined) await element.focus();
        self.isAgent = currentUser;
        self.replyContainer = true;
        self.replyData = reply;
        self.replyData.origin = InteractionOrigin.REF;

        switch (reply.type) {
          case "MESSAGE":
            if (self.imageUrl) {
              self.imageUrl = "";
            }
            break;

          case "IMAGE":
            await self.dataUrlToFile(reply.fileurl).then((res: string) => {
              self.imageUrl = res;
            });
            break;

          case "FILE":
            if (self.imageUrl) {
              self.imageUrl = "";
            }
            break;
          default:
            break;
        }
        self.replyText = reply.text[0];
      }
    );
    this.errorToast = $("#error-toast");
  }

  @Watch("currentConversation")
  changeInteractions(): void {
    this.reset();
    this.emojiCategories = Object.keys(allEmojis);
    this.getEmojis(this.emojiCategories[0]);
  }

  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : "";
    if (consumer.includes("@")) consumer = consumer.split("@")[0];
    return consumer;
  }

  reset(): void {
    this.message = "";
    this.messageSend = "";
    this.emogiSearch = "";
    this.imageList = [];
    this.fileList = [];
    this.fileListEmpty = [];
    this.limitAlert = false;
    this.audioList = [];
    this.audio = null;
    this.imageListempty = [];
    this.uploading = false;
    this.visiableAttachmentForm = false;
    this.visibleEmojis = false;
    this.visibleQuickResponses = false;
    this.previewVisible = false;
    this.previewImage = "";
    this.toUploadFiles = 0;
    this.uploadedFiles = 0;
    this.emojiCategories = [];
    this.actualCategory = "";
    this.audioSender = false;
    this.originalFileOrder = [];
    this.originalImageOrder = [];
    this.isError = false;
  }

  async onPaste(event: ClipboardEvent): Promise<any> {
    const clipboardData: DataTransfer | null = event.clipboardData;
    if (clipboardData) {
      const file: File | null = clipboardData.items[0].getAsFile();
      if (file) {
        this.visibleEmojis = false;
        this.visibleQuickResponses = false;
        this.audioSender = false;
        if (file.size < 40000000) {
          Object.assign(file, { uid: Math.round(Math.random() * 100000) });
          const info = { file };
          await this.handleChange(info);
        } else {
          this.$message.error(`El archivo ${file.name} es muy grande.`);
        }
      }
    }
  }

  get interactions(): any {
    return store.state.agent!.interactions;
  }
  @Watch("interactions")
  isLastInteractionFailed(): void {
    const lastInteraction = this.interactions[this.interactions.length - 1];

    // search the last interaction mode INPUT reversed
    const lastInteractionInput = this.interactions
      .slice()
      .reverse()
      .find((interaction: Interaction) => interaction.mode === "INPUT");

    // check if the created date interaction is minor than 24 hours
    let date = new Date(lastInteractionInput!.createdAt);
    let now = new Date();
    let diff = Math.abs(now.getTime() - date.getTime());
    let diffHours = Math.ceil(diff / (1000 * 3600));
    if (diffHours > 24) {
      this.isError = true;
      eventBus.$emit("Error-Interaction", {
        errors: [
          {
            title: "It has been more than 24 hours since the last interaction",
          },
        ],
        isError: true,
      });
      return;
    }

    if (lastInteraction._errors.length > 0) {
      this.isError = true;
      eventBus.$emit("Error-Interaction", {
        errors: lastInteraction._errors,
        isError: true,
      });
      // close on 3 seconds then open modal
      Swal.fire({
        title: "Error",
        text: lastInteraction._errors[0].title,
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          this.isModalVisible = true;
          this.getTemplates();
        }
      });

      // this.isModalVisible = true;
      // this.getTemplates();
    } else {
      this.isError = false;
      eventBus.$emit("Error-Interaction", {
        errors: [],
        isError: false,
      });
    }
    // const lastInteraction = this.interactions[this.interactions.length - 1];
    // if (lastInteraction._errors) {
    //   this.$message.error(lastInteraction._errors[0].title);s
    //   this.isError = true;
    // }
  }
  getEmojis(category: string): void {
    this.actualCategory = category;
    let temporalEmojis: any = allEmojis;
    let filterEmojis: any = temporalEmojis[this.actualCategory];
    this.emojis = Object.values(filterEmojis);
  }

  insert(emoji: string): void {
    this.setContentInMessage(emoji);
  }

  generateAudioName(): string {
    return `${this.currentConversation._id}${Date.now()}.mp3`;
  }

  async afterRecord(data: any): Promise<any> {
    this.audio = data.blob;
    await this.sendMessage();
    this.showAudioSender();
  }

  removeAll(): void {
    this.fileList = [];
    this.imageList = [];
    this.originalFileOrder = [];
    this.originalImageOrder = [];
    this.toUploadFiles = 0;
    this.uploadedFiles = 0;
    this.limitAlert = false;
  }

  handleRemove(file: any): void {
    let index = this.imageList.indexOf(file);
    let indexOriginal = this.originalImageOrder.indexOf(file.uid);

    if (index != -1) {
      const newimageList = this.imageList.slice();
      const newOriginalImageList = this.originalImageOrder.slice();
      newimageList.splice(index, 1);
      newOriginalImageList.splice(indexOriginal, 1);
      this.imageList = newimageList;
      this.originalImageOrder = newOriginalImageList;
    }

    index = this.fileList.indexOf(file);
    indexOriginal = this.originalFileOrder.indexOf(file.uid);

    if (index != -1) {
      const newfileList = this.fileList.slice();
      const newOriginalFileList = this.originalFileOrder.slice();
      newfileList.splice(index, 1);
      newOriginalFileList.splice(indexOriginal, 1);
      this.fileList = newfileList;
      this.originalFileOrder = newOriginalFileList;
    }

    this.toUploadFiles = this.fileList.length + this.imageList.length;
    this.limitAlert = false;
  }

  beforeUpload(): boolean {
    return false;
  }

  getBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  handleCancel(): void {
    this.previewVisible = false;
  }

  @Watch("fileList")
  updateToUploadFiles(): void {
    this.uploadedFiles = this.fileList.length + this.imageList.length;
  }

  @Watch("imageList")
  updateToUploadImages(): void {
    this.uploadedFiles = this.fileList.length + this.imageList.length;
  }

  handleChange(info: any): void {
    this.visibleQuickResponses = false;
    this.visibleEmojis = false;
    this.audioSender = false;

    const status = info.file.status;

    if (info.file.size < 40000000) {
      if (status != "done") {
        let uploader: Promise<any>;

        if (info.file.originFileObj) {
          uploader = this.handleUpload(info.file.originFileObj);
        } else {
          uploader = this.handleUpload(info.file);
        }

        if (info.file.type.indexOf("image/") !== -1) {
          this.originalImageOrder.push(info.file.uid);
        } else {
          this.originalFileOrder.push(info.file.uid);
        }

        uploader
          .then((file: any) => {
            console.log("file", file);
            if (file.type.indexOf("image/") !== -1) {
              this.imageList.push(file);
            } else {
              this.fileList.push(file);
            }

            this.updateToUploadFiles();
            this.updateToUploadImages();

            file.status = "done";
            this.verifyUploadedFilesMatches();

            if (this.toUploadFiles === this.uploadedFiles) {
              this.uploading = false;
              this.limitAlert = false;
              this.reArrangeImageList();
              this.reArrangeFileList();
            }
          })
          .catch(() => {
            if (info.file.type.indexOf("image/") !== -1) {
              this.originalImageOrder.pop();
            } else {
              this.originalFileOrder.pop();
            }
          });
      }
    } else {
      this.$notification.error({
        message: "Acción no valida",
        description: `El archivo ${info.file.name} excede el tamaño maximo`,
      });
    }
  }

  verifyUploadedFilesMatches() {
    this.fileList.forEach((file: any) => {
      if (!this.originalFileOrder.includes(file.uid)) {
        let index = this.fileList.indexOf(file);
        const newFilesList = this.fileList.slice();
        newFilesList.splice(index, 1);
        this.fileList = newFilesList;
      }
    });

    this.imageList.forEach((image: any) => {
      if (!this.originalImageOrder.includes(image.uid)) {
        let index = this.imageList.indexOf(image);
        const newImagesList = this.imageList.slice();
        newImagesList.splice(index, 1);
        this.imageList = newImagesList;
      }
    });
  }

  reArrangeImageList() {
    let tempImageList = this.imageList;
    this.imageList = [];
    this.originalImageOrder.forEach((image: any) => {
      let localImage = null;
      tempImageList.forEach((tempImage) => {
        if (tempImage && tempImage.uid === image) {
          localImage = tempImage;
        }
      });
      this.imageList.push(localImage);
    });
  }

  reArrangeFileList() {
    let tempFileList = this.fileList;
    this.fileList = [];
    this.originalFileOrder.forEach((file: any) => {
      let localFile = null;
      tempFileList.forEach((tempFile) => {
        if (tempFile && tempFile.uid === file) {
          localFile = tempFile;
        }
      });
      this.fileList.push(localFile);
    });
  }

  async handlePreview(file: any): Promise<any> {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;

    this.EventBus.$emit("image-modal", {
      formatedFileName: Math.round(Math.random() * 100000),
      dataFileUrl: this.previewImage,
    });
  }

  handleUpload(file: any): Promise<File> {
    return new Promise(async (resolve, reject) => {
      const limit = 10;
      if (this.toUploadFiles >= limit) {
        if (this.toUploadFiles === limit && !this.limitAlert) {
          this.$notification.error({
            message: "Acción no valida",
            description: `El limite de envio es de ${limit} archivos`,
          });
          this.limitAlert = true;
          setTimeout(() => (this.limitAlert = false), 500);
        }
        reject();
      } else {
        this.toUploadFiles++;
        const formData = new FormData();

        formData.append("image", file);
        formData.append("contentType", file.type);

        this.uploading = true;
        await Axios.post(`${BASE_URL_MANAGER}/company/fileupload/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(async (resp: any) => {
            let data = resp.data;
            if (data.success === true) {
              file.originalUrl = resp.data.urlfile;
              await this.dataUrlToFile(file.originalUrl).then(
                (response: string) => (file.url = response)
              );
              resolve(file);
            } else {
              this.uploading = false;
              file.status = "error";
              reject();
            }
          })
          .catch(() => {
            this.uploading = false;
            file.status = "error";
            reject();
          });
      }
    });
  }

  handleUploadAudio(blob: any): void {
    const formData = new FormData();
    let name = this.generateAudioName();
    let file = new File([blob], name, { type: blob.type });
    formData.append("audio", file);
    this.uploading = true;

    Axios.post(`${BASE_URL_MANAGER}/company/audioupload/`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (resp: any) => {
        let data = resp.data;
        if (data.success === true) {
          let url = resp.data.urlfile;
          const idTextMessage = Math.random().toString(36).substr(2, 9);
          this.EventBus.$emit("sendingMessage", idTextMessage);
          console.log("audiofile", file);
          await store
            .dispatch(
              storeTypes.conversation.actions.sendMessage({
                id: idTextMessage,
                type: MessageType.AUDIO,
                fileurl: url,
                filename: file.name,
                mimetype: "audio/mpeg",
                origin: this.replyData.origin,
              })
            )
            .finally(() => {
              this.EventBus.$emit("sentMessage", idTextMessage);
              this.bus.$emit("clearRecordList");
              this.audio = null;
              this.uploading = false;
            })
            .catch(() => {
              this.errorToast.removeClass("d-none");
              this.errorToast.toast("show");
              setTimeout(() => this.errorToast.toast("hide"), 2000);
            });
        }
      })
      .catch(() => (this.uploading = false));
  }

  async asyncForEach(array: any, callback: any) {
    this.sendingMessage = true;
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
    this.sendingMessage = false;
  }

  async sendMessage(): Promise<any> {
    this.toUploadFiles = 0;
    this.uploadedFiles = 0;
    this.visibleEmojis = false;
    this.visibleQuickResponses = false;
    const idTextMessage = Math.random().toString(36).substring(2, 9);
    if (this.message && this.message.length > 0 && this.message.trim() !== "") {
      const currentTextMessage = this.message;
      this.EventBus.$emit("sendingMessage", idTextMessage);
      this.replyContainer = false;
      this.message = "";
      await store
        .dispatch(
          storeTypes.conversation.actions.sendMessage({
            id: idTextMessage,
            type: MessageType.MESSAGE,
            text: [currentTextMessage],
            referenceId: this.replyData._id,
            origin: this.replyData.origin,
          })
        )
        .finally(() => {
          this.EventBus.$emit("sentMessage", idTextMessage);
        })
        .catch(() => {
          this.errorToast.removeClass("d-none");
          this.errorToast.toast("show");
          setTimeout(() => this.errorToast.toast("hide"), 2000);
        });
      this.replyData = {};
    }

    if (this.imageList.length > 0) {
      this.EventBus.$emit("sendingMessage", idTextMessage);

      await this.asyncForEach(this.imageList, async (file: any) => {
        console.log("sending file", file);
        await this.sendFile(file, MessageType.IMAGE, idTextMessage);
      });

      this.imageList = [];
      this.originalImageOrder = [];
      this.EventBus.$emit("sentMessage", idTextMessage);
    }

    if (this.fileList.length > 0) {
      this.EventBus.$emit("sendingMessage", idTextMessage);

      await this.asyncForEach(this.fileList, async (file: any) => {
        await this.sendFile(file, MessageType.FILE, idTextMessage);
      });

      this.fileList = [];
      this.originalFileOrder = [];
      this.EventBus.$emit("sentMessage", idTextMessage);
    }

    if (this.audio != null) {
      this.handleUploadAudio(this.audio);
    }
  }

  async sendFile(file: any, type: MessageType, id: string): Promise<any> {
    this.replyContainer = false;
    await store
      .dispatch(
        storeTypes.conversation.actions.sendMessage({
          id,
          type,
          fileurl: file.url,
          filename: file.name,
          mimetype: file.type,
          referenceId: this.replyData._id,
          origin: this.replyData.origin,
        })
      )
      .then(() => {
        this.replyData = {};
      })
      .finally(() => {
        this.EventBus.$emit("sentMessage", id);
      })
      .catch((error) => {
        console.log("error", error);
        this.errorToast.removeClass("d-none");
        this.errorToast.toast("show");
        setTimeout(() => this.errorToast.toast("hide"), 2000);
      });
  }

  setContentInMessage(message: string): void {
    this.message += message;
  }

  showAttachmentSite(): void {
    if (this.visiableAttachmentForm) {
      this.$el.setAttribute("style", "bottom: 0px");
      this.visiableAttachmentForm = false;
      this.EventBus.$emit("showAttachmentSite", 0);
    } else {
      this.$el.setAttribute("style", "bottom: 100px");
      this.visiableAttachmentForm = true;
      this.EventBus.$emit("showAttachmentSite", 100);
    }
  }

  setVisibleEmojis(): void {
    this.visibleEmojis = !this.visibleEmojis;
    this.visibleQuickResponses = false;
    this.audioSender = false;
    this.imageList = [];
    this.originalFileOrder = [];
    this.originalImageOrder = [];
    this.fileList = [];
    const element = <Vue>this.$refs["input"];
    const input = <HTMLInputElement>element.$el;
    input.focus();
  }

  setVisibleQuickResponses(): void {
    this.visibleEmojis = false;
    this.visibleQuickResponses = !this.visibleQuickResponses;
    this.audioSender = false;
    this.imageList = [];
    this.originalFileOrder = [];
    this.originalImageOrder = [];
    this.fileList = [];
    const element = <Vue>this.$refs["input"];
    const input = <HTMLInputElement>element.$el;
    input.focus();
  }

  async dataUrlToFile(url: string | any): Promise<string> {
    this.loading = true;
    let tempUrl = "";
    await Axios.post(`${BASE_URL_MANAGER}/company/getfile`, {
      urlfile: url,
    }).then((response) => {
      tempUrl = response.data.file;
      this.loading = false;
    });
    return tempUrl;
  }

  triggerDeleteEvent(values: any) {
    this.$emit("delete", values);
  }

  sendQuickResponse(values: any) {
    this.visibleQuickResponses = false;
    this.$emit("send", values);
  }
}
