import {Component, Prop} from "vue-property-decorator";
import {VueWizard} from "@/vue-wizard";
import $ from 'jquery';
import {BusinessStatus} from "@/app/store/modules/conversation/conversation.models";
import {fromEvent, Subscription} from 'rxjs';

@Component({name: 'change-conversation-status-modal'})
export default class ChangeConversationStatusModal extends VueWizard {

    @Prop()
    public readonly selectedStatus!: BusinessStatus;
    @Prop()
    public readonly actualBusinessStatus?: BusinessStatus;
    @Prop()
    public readonly actualStatus?: string;

    private modalReference: JQuery<HTMLElement> | null = null;
    private modalHideSubscription: Subscription | null = null;

    mounted(): void {
        this.modalReference = $('#change-conversation-status-modal');
        this.modalReference.modal('show');
        this.modalHideSubscription = fromEvent(this.modalReference, 'hidden.bs.modal').subscribe(() => {
            this.$emit('modalHideNoConfirm');
            if(this.modalHideSubscription) {
                this.modalHideSubscription.unsubscribe();
            }
        });
    }

    changeConversationStatus(): void {
        if(this.modalHideSubscription) {
            this.modalHideSubscription.unsubscribe();
        }
        if(this.modalReference) {
            this.modalReference.modal('hide');
        }
        this.$emit('changeConversationStatus', this.selectedStatus);
    }

    statusColorStyle(status: BusinessStatus): any {
        return {
            '--label-bg-color-r': this.hexToRgb(status.color) ? (this.hexToRgb(status.color) as any).r : 0,
            '--label-bg-color-g': this.hexToRgb(status.color) ? (this.hexToRgb(status.color) as any).g : 0,
            '--label-bg-color-b': this.hexToRgb(status.color) ? (this.hexToRgb(status.color) as any).b : 0
        }
    }

    hexToRgb(hex: string): any {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

}