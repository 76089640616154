import {Component} from "vue-property-decorator";
import {VueWizard} from "@/vue-wizard";
import {Subscription, timer} from 'rxjs';

@Component({name: "load-bar"})
export default class LoadBar extends VueWizard {

    public intervalSubscription: Subscription | null = null;
    public barAdvance = 0;

    mounted(): void {
        this.intervalSubscription = timer(500, 500)
            .subscribe(() => this.barAdvance === 130 ? this.barAdvance = 0 : this.barAdvance += 10)
    }

    beforeDestroy(): void {
        if(this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

}