var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-0 rounded-0"},[_c('perfect-scrollbar',{staticClass:"scroll-area"},[_c('div',{attrs:{"id":(_vm.categoryIdentification + "-conversations-heading")}},[_c('div',{staticClass:"pb-3 d-flex flex-row align-items-center justify-content-between",attrs:{"data-target":("#" + _vm.categoryIdentification + "-conversations-collapse"),"aria-expanded":"true","aria-controls":(_vm.categoryIdentification + "-conversations-collapse")}},[(_vm.activeFilters.length > 0)?_c('small',[_vm._l((_vm.activeFilters),function(filter){return _c('small',{key:filter.id,staticClass:"badge badge-info mr-1"},[_vm._v(_vm._s(filter))])}),_c('small',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.conversations.length))])],2):_vm._e()])]),_c('div',{attrs:{"id":(_vm.categoryIdentification + "-conversations-collapse"),"aria-labelledby":"headingOne","data-parent":_vm.parentAccordion}},[(_vm.loading)?_c('div',{staticClass:"text-center text-info"},[_c('font-awesome-icon',{attrs:{"icon":"sync","spin":"","size":"2x"}})],1):_c('div',{staticStyle:{"height":"100%, min-height:100px"}},[_c('div',[_vm._t("filters")],2),_c('div',{staticClass:"d-flex flex-column"},[(_vm.conversations.length === 0)?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[(_vm.activeFilters.length > 0)?_c('a-empty',{staticClass:"text-secondary",attrs:{"description":"No hay conversaciones con este filtro en esta categoria"}}):_c('a-empty',{staticClass:"text-secondary",attrs:{"description":"No hay conversaciones en esta categoria"}})],1):_vm._e(),_c('ul',{staticClass:"user-list mt-2 bg-light conversation-item"},_vm._l((_vm.conversations),function(conversation,index){return _c('li',{key:index,staticClass:"user-list-item",class:{
                active: conversation._id === _vm.currentConversation._id,
                'bordered-active':
                  conversation._id === _vm.currentConversation._id &&
                  _vm.getBusinessStatus(conversation.bussinesStatus),
                'no-bordered-active':
                  conversation._id === _vm.currentConversation._id &&
                  !_vm.getBusinessStatus(conversation.bussinesStatus),
              },style:(_vm.statusColorStyle(
                  _vm.getBusinessStatus(conversation.bussinesStatus)
                )),on:{"click":function($event){return _vm.onSelect(conversation)}}},[_c('div',{staticClass:"avatar avatar-online"},[(_vm.getUserPicture(conversation) != '')?_c('img',{class:conversation.isClosed
                      ? 'rounded-circlepicture-behavior closed'
                      : 'rounded-circle picture-behavior',attrs:{"src":_vm.getUserPicture(conversation),"alt":"image"}}):_vm._e(),(_vm.getUserPicture(conversation) == '')?_c('div',{staticClass:"letter-avatar"},[_vm._v(" "+_vm._s(_vm.geInitials(conversation))+" ")]):_vm._e()]),_c('div',{staticClass:"users-list-body"},[_c('div',[_c('h5',[_vm._v(_vm._s(_vm.getUserName(conversation)))]),_c('p',[_vm._v(" "+_vm._s(_vm.getConsumer(conversation))+" "),(
                        Object.keys(
                          _vm.statusColorStyle(
                            _vm.getBusinessStatus(conversation.bussinesStatus)
                          )
                        ).length > 0
                      )?_c('small',{staticClass:"badge color-indicator"},[_c('i')]):_vm._e()]),_c('div',{staticClass:"mb-1"},[_c('small',{staticClass:"text-info"},[_c('small',[_c('b',[_vm._v(_vm._s(_vm.getTopic(conversation)))])])]),(conversation.status === 'TRANSFERRED')?_c('small',{staticClass:"badge badge-warning m-2"},[_vm._v("Transfer")]):_vm._e(),(conversation.status === 'NEW')?_c('small',{staticClass:"badge badge-success"},[_vm._v("Nueva")]):_vm._e()])]),_c('div',{staticClass:"last-chat-time"},[(
                      _vm.categoryIdentification === 'queue' &&
                      conversation.updatedAt
                    )?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.formatUpdatedDate(conversation.updatedAt)))]):_vm._e(),(
                      _vm.categoryIdentification === 'active' &&
                      conversation.updatedAt
                    )?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.formatUpdatedDate(conversation.updatedAt)))]):_vm._e(),(conversation.newinteractions > 0)?_c('div',{staticClass:"new-message-count"},[_vm._v(" "+_vm._s(conversation.newinteractions)+" ")]):_vm._e(),_c('font-awesome-icon',{staticClass:"mt-1",class:{
                      'text-success':
                        conversation.currentChannel !== 'MESSENGER',
                      'text-primary':
                        conversation.currentChannel === 'MESSENGER',
                    },attrs:{"size":"lg","icon":conversation.currentChannel === 'MESSENGER'
                        ? ['fab', 'facebook-messenger']
                        : ['fab', 'whatsapp']}})],1)])])}),0),_c('button',{staticClass:"btn btn-info text-white",on:{"click":function($event){return _vm.loadMore()}}},[_vm._v(" Página "+_vm._s(_vm.currentPage)+" - Cargar mas... ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }